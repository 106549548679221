class CartAbstract {
  constructor() {
    this.cache = 'no-store';
    this.credentials = 'same-origin';
  }

  get(url = '/shop-api/cart') {
    return fetch(url, {
      credentials: this.credentials,
      cache: this.cache,
    });
  }

  add(item, quantity = 1, data = []) {
    return fetch('/shop-api/cart', {
      method: 'POST',
      credentials: this.credentials,
      cache: this.cache,
      body: JSON.stringify({
        id: item,
        quantity,
        data,
      }),
    });
  }

  update(item, quantity, data = []) {
    return fetch('/shop-api/cart', {
      method: 'PATCH',
      credentials: this.credentials,
      cache: this.cache,
      body: JSON.stringify({
        key: item,
        quantity,
        data,
      }),
    });
  }

  remove(item) {
    return fetch('/shop-api/cart', {
      method: 'DELETE',
      credentials: this.credentials,
      cache: this.cache,
      body: JSON.stringify({
        id: item,
      }),
    });
  }
}

export default CartAbstract;
