class Form {
  constructor(element) {
    const {
      action,
    } = element;

    const errorsElement = element.querySelector('.m-form__errors');

    this.element = element;
    this.errorsElement = errorsElement;
    this.formSuccessElement = document.querySelector('.m-form-success');

    function appendErrorElement(text) {
      const errorElement = document.createElement('div');
      errorElement.innerText = text;
      errorsElement.appendChild(errorElement);
    }

    element.addEventListener('submit', (event) => {
      event.preventDefault();
      element.classList.add('-loading');

      while (this.errorsElement.firstChild) {
        this.errorsElement.removeChild(this.errorsElement.firstChild);
      }

      (async () => {
        const rawResponse = await fetch(action, {
          method: 'POST',
          body: new FormData(element),
        });

        element.classList.remove('-loading');

        if (rawResponse.ok) {
          const content = await rawResponse.json();
          if (content.alerts.length === 0) {
            this.showSuccess();
          } else {
            for (const property in content.alerts) {
              appendErrorElement(`${property}: ${content.alerts[property]}`);
            }
          }
        } else {
          appendErrorElement(`Fehler ${rawResponse.status}: ${rawResponse.statusText}`);
        }
      })();
    });
  }

  showSuccess() {
    const {
      element,
      formSuccessElement,
    } = this;

    element.setAttribute('hidden', '');
    formSuccessElement.removeAttribute('hidden');
    formSuccessElement.scrollIntoView({
      behavior: 'smooth',
    });
  }
}

export default Form;
