import * as Klaro from 'klaro/dist/klaro-no-css';

const config = {
  acceptAll: true,
  translations: {
    de: {
      decline: 'Ablehnen',
      ok: 'Akzeptieren',
      consentNotice: {
        description: 'Diese Seite verwendet Cookies und Drittanbieterdienste. Sie haben hier die Möglichkeit diese zu akzeptieren oder abzulehnen.',
      },
      mapbox: {
        description: 'Mapbox ermöglicht die Darstellung von sMAIL-Briefkästen auf einer Karte.',
      },
      youtube: {
        title: 'YouTube',
        description: 'YouTube kann an manchen Stellen eingebettet sein um Videos anzuzeigen.',
      },
      vimeo: {
        title: 'Vimeo',
        description: 'Vimeo kann an manchen Stellen eingebettet sein um Videos anzuzeigen.',
      },
      'google-tag-manager': {
        description: 'Mit Google Tag Manager wird Ihr Besuch auf unserer Seite aufgezeichnet und ausgewertet.',
      },
      'facebook-pixel': {
        description: 'Durch das Facebook Pixel wird Ihr Besuch auf dieser Website aufgezeichnet und kann anschließend ausgewertet werden. Die Verarbeitung der Daten erfolgt durch Meta (Facebook).',
      },
      stripe: {
        title: 'Stripe',
        description: 'Stripe ermöglicht das Bezahlen unter anderem per Kreditkarte.',
      },
      userlike: {
        title: 'Userlike',
        description: 'Mit Userlike können wir direkt mit Ihnen chatten.',
      },
      purposes: {
        analytics: 'Analyse',
        shop: {
          title: 'Shop-Funktion',
        },
        map: {
          title: 'Kartendienste',
        },
        liveChat: {
          title: 'Live-Chat',
        },
        video: {
          title: 'Videos',
        },
      },
    },
  },
  services: [
    {
      name: 'google-tag-manager',
      purposes: ['analytics'],
    },
    {
      name: 'facebook-pixel',
      purposes: ['analytics'],
    },
    {
      name: 'mapbox',
      purposes: ['map'],
    },
    {
      name: 'youtube',
      purposes: ['video'],
    },
    {
      name: 'userlike',
      purposes: ['liveChat'],
    },
    {
      name: 'vimeo',
      purposes: ['video'],
    },
    {
      name: 'stripe',
      purposes: ['shop'],
      required: true,
    },
  ],
};

// we assign the Klaro module to the window, so that we can access it in JS
window.klaro = Klaro;
// window.klaroConfig = config;
// we set up Klaro with the config
Klaro.setup(config);
