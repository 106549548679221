class NewsItemVideo {
  constructor(element) {
    this.element = element;
    const videoElement = element.querySelector('video');
    let userClickedOnce = false;


    if (videoElement) {
      videoElement.addEventListener('click', (event) => {
        if (userClickedOnce === false && videoElement.muted === true) {
          event.preventDefault();
          videoElement.muted = false;
          userClickedOnce = true;
        }
      });

      document.addEventListener('scroll', () => {
        if (this.isInViewport()) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      }, {
        passive: true,
      });

      if (this.isInViewport()) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
    }
  }

  isInViewport() {
    const rect = this.element.getBoundingClientRect();
    const height = this.element.offsetHeight;
    const width = this.element.offsetWidth;
    return (rect.top >= -height
      && rect.left >= -width
      && rect.right <= (window.innerWidth || document.documentElement.clientWidth) + width
      && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + height);
  }
}

export default NewsItemVideo;
