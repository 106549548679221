class Popup {
  constructor(element) {
    const buttonCloseElement = element.querySelector('.a-button-close ');
    const { id } = element;

    this.element = element;

    function onHashchange() {
      if (window.location.hash.substring(1) === id) {
        this.show();
      } else {
        this.hide();
      }
    }

    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        if (element.hidden === false) {
          event.stopPropagation();
        }
        this.hide();
      }
    }, true);
    element.addEventListener('click', (event) => {
      event.stopPropagation();
      if (event.target === element) {
        this.hide();
      }
    });
    window.addEventListener('hashchange', onHashchange.bind(this));
    buttonCloseElement.addEventListener('click', this.hide.bind(this));
    onHashchange.bind(this)();
  }


  show() {
    const {
      element,
    } = this;

    if (element.hidden === true) {
      element.removeAttribute('hidden');
      document.body.classList.add('-no-scroll');
    }
  }

  hide() {
    const {
      element,
    } = this;

    if (element.hidden === false) {
      window.location.hash = '';
      element.setAttribute('hidden', '');
      document.body.classList.remove('-no-scroll');
    }
  }
}

export default Popup;
