class Lightbox {
  constructor(sourceElement) {
    const {
      fullImage,
    } = sourceElement.dataset;

    const fullImageElement = document.createElement('img');
    fullImageElement.setAttribute('src', fullImage);
    fullImageElement.setAttribute('alt', '');

    this.sourceElement = sourceElement;
    this.element = document.createElement('div');
    this.element.classList.add('m-lightbox');
    this.buttonCloseElement = document.createElement('button');
    this.buttonCloseElement.classList.add('a-button-close', '-white');
    this.buttonCloseElement.setAttribute('title', 'Lightbox schließen');
    this.buttonCloseElement.setAttribute('type', 'button');

    this.element.appendChild(this.buttonCloseElement);
    this.element.appendChild(fullImageElement);

    this.buttonCloseElement.addEventListener('click', () => {
      this.close();
    });
    sourceElement.addEventListener('click', () => {
      this.open();
    });
    this.element.addEventListener('click', (event) => {
      if ((event.target === fullImageElement) ? false : !fullImageElement.contains(event.target)) {
        this.close();
      }
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.close();
      }
    });
  }

  open() {
    const {
      element,
      buttonCloseElement,
    } = this;

    const bodyElement = document.body;
    bodyElement.appendChild(element);
    buttonCloseElement.focus();
  }

  close() {
    const {
      element,
      sourceElement,
    } = this;
    if (element.parentNode) {
      element.parentNode.removeChild(element);
      sourceElement.focus();
    }
  }
}

export default Lightbox;
