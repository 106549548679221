import CartAbstract from './CartAbstract';
import Popup from './Popup';

class Cart extends CartAbstract {
  constructor(element) {
    super();

    const headerElement = document.querySelector('.o-header');
    const url = element.dataset.url ? element.dataset.url : undefined;
    const buttonContinueElement = element.querySelector('.m-cart__button-continue');
    const buttonCheckoutElement = element.querySelector('.m-cart__button-checkout');

    this.buttonElement = headerElement.querySelector('.a-button-cart');
    this.element = element;
    this.buttonCheckoutElement = buttonCheckoutElement;
    this.buttonContinueElement = buttonContinueElement;
    this.countElement = this.buttonElement.querySelector('.a-button-cart__count');
    this.basketBodyElement = this.buttonElement.querySelector('#body');
    this.oHeaderBackdropElement = headerElement.querySelector('.o-header__backdrop');
    this.data = null;
    this.isInvoice = element.classList.contains('-invoice');
    this.isMouseOver = false;


    element.addEventListener('transitionend', (event) => {
      if (event.target === element && !element.classList.contains('-expanded')) {
        element.hidden = true;
      }
    });
    element.addEventListener('mouseenter', () => {
      this.isMouseOver = true;
    });
    element.addEventListener('mouseleave', () => {
      this.isMouseOver = false;
    });
    if (buttonContinueElement) {
      buttonContinueElement.addEventListener('click', () => {
        this.hide();
      });
    }
    this.buttonElement.addEventListener('click', () => {
      if (this.buttonElement.getAttribute('aria-expanded') === 'false') {
        this.show();
      } else {
        this.hide();
      }
    });
    document.addEventListener('click', (event) => {
      if (this.buttonElement.getAttribute('aria-expanded') === 'true'
      && event.target.closest('.a-button-cart') !== this.buttonElement
      && event.target.closest('.m-cart') === null) {
        this.hide();
      }
    });
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.hide();
      }
    });


    (async () => {
      element.classList.add('-loading');
      const response = await super.get(url);
      const data = await response.json().catch((error) => {
        console.info(error);
      });
      element.classList.remove('-loading');
      this.data = data;
      if (response.status === 200) {
        this.updateCount();
        this.createCartList();
      } else {
        this.createCartList();
        console.info(response);
      }
    })();
  }


  updateCount() {
    const {
      countElement,
      basketBodyElement,
      data,
    } = this;
    const quantity = data ? data.quantity : 0;
    if (countElement && basketBodyElement) {
      if (quantity !== 0) {
        basketBodyElement.style.fill = '#ffffff';
        countElement.innerText = quantity > 9 ? '•' : quantity;
      } else {
        basketBodyElement.style.fill = 'transparent';
        countElement.innerText = '';
      }
    }
  }


  createCartList() {
    const {
      element,
      data,
      buttonCheckoutElement,
      isInvoice,
    } = this;
    const listElement = element.querySelector('.m-cart__list');

    function createItemPopup(itemData, popupId) {
      const existingPopup = document.getElementById(popupId);
      if (existingPopup) {
        existingPopup.remove();
      }
      const popupElement = document.createElement('div');
      popupElement.classList.add('o-popup');
      popupElement.id = popupId;
      popupElement.setAttribute('hidden', '');
      popupElement.innerHTML = `
        <div class="o-popup__content">
          ${itemData.details}
          <button type="button" class="a-button-close" title="Popup schließen"></button>
        </div>
      `;
      new Popup(popupElement);
      document.body.appendChild(popupElement);
    }

    function createItem(itemData) {
      // same as snippets/m-cart__list.php
      let html = '<div class="m-cart-item">';
      if (itemData.url && itemData.src) {
        html += `
        <figure aria-hidden="true">
        <a href="${itemData.url}">
        <img src="${itemData.src}" alt="">
        </a>
        </figure>
        `;
      }
      html += '<div class="m-cart-item__text">';

      if (itemData.url) {
        html += `<a href="${itemData.url}" class="m-cart-item__title a-product-title">`;
      } else {
        html += '<span class="m-cart-item__title a-product-title">';
      }
      html += `
        <strong>${itemData.title}</strong><br>
      `;
      if (itemData.url) {
        html += '</a>';
      } else {
        html += '</span>';
      }

      if (itemData.text) {
        html += `<p>${itemData.text}</p>`;
      }

      if (itemData.details) {
        if (isInvoice === true) {
          html += itemData.details;
        } else {
          const popupId = `details-${itemData.key}`;
          html += `
            <p><a class="a-link -secondary" href="#${popupId}">Details anzeigen</a></p>
          `;
          createItemPopup(itemData, popupId);
        }
      }

      html += '<div class="m-cart-item__price-container">';

      if (isInvoice === true || itemData.template === 'service') {
        html += `${itemData.quantity}`;
      } else {
        html += `<select aria-label="${itemData.quantity} im Warenkorb. Anzahl ändern." class="a-select" data-action="update" data-id="${itemData.key}">`;
        for (let i = 1; i <= itemData.maxAmount; i += 1) {
          if (i === itemData.quantity) {
            html += `<option selected value="${i}">${i}</option>`;
          } else {
            html += `<option value="${i}">${i}</option>`;
          }
        }
        html += '</select>';
      }
      html += `<span class="m-cart-item__times">×</span> <span class="m-cart-item__price">${itemData.price}</span>`;
      html += `
        <div class="m-cart-item__sum">
          ${itemData.sum}
        </div>
      `;
      html += '</div>';

      html += '</div>';
      if (isInvoice !== true) {
        html += `<button type="button" data-action="remove" data-id="${itemData.key}">Entfernen</button>`;
      }
      html += '</div>';
      return html;
    }

    function onSelectChange(event) {
      const { dataset, value } = event.target;
      if (dataset.action === 'update') {
        this.update(dataset.id, value);
      }
    }

    function onButtonClick(event) {
      const { dataset } = event.target;
      if (dataset.action === 'remove') {
        this.remove(dataset.id);
      }
    }

    if (data) {
      let html = '';

      if (data.message) {
        html += `
        <div class="m-cart__message -${data.status}">
        ${data.message}
        </div>
        `;
      }

      if (data.items.length > 0) {
        data.items.filter((item) => item.template !== 'shipping').forEach((item) => {
          html += createItem(item);
        });

        const shipping = data.items.find((item) => item.template === 'shipping');
        if (shipping) {
          const shippingText = parseFloat(shipping.price) === 0.0 ? 'kostenlos' : shipping.price;

          html += `
          <div class="cart__shipping">
          <div>${shipping.title}</div>
          <div><strong>${shippingText}</strong></div>
          </div>
          `;
        }

        if (data.sum) {
          html += `
          <div class="m-cart__sum">
          <div><strong>Gesamtsumme</strong> <small>${data.taxRates.length > 0 ? '(inkl. MwSt.)' : '(umsatzsteuerfrei)'}</small></div>
          <div><strong>${data.sum}</strong></div>
          </div>
          `;
        }

        data.taxRates.forEach((taxRate) => {
          html += `
          <div class="m-cart__tax">
          <div>Enthaltene MwSt. <small>(${taxRate.taxRate} %)</small></div>
          <div><strong>${taxRate.sum}</strong></div>
          </div>
          `;
        });
      } else {
        // empty cart
      }

      if (buttonCheckoutElement) {
        buttonCheckoutElement.hidden = data.items.length === 0;
      }

      listElement.innerHTML = html;

      if (data.items) {
        listElement.querySelectorAll('button').forEach((buttonElement) => {
          buttonElement.addEventListener('click', onButtonClick.bind(this));
        });
        listElement.querySelectorAll('select').forEach((selectElement) => {
          selectElement.addEventListener('change', onSelectChange.bind(this));
        });
      }
    }
  }

  show() {
    const {
      element,
      buttonElement,
      buttonCheckoutElement,
      buttonContinueElement,
      oHeaderBackdropElement,
    } = this;

    function isElementInViewport(_element) {
      const {
        top,
        left,
        bottom,
        right,
      } = _element.getBoundingClientRect();
      return (
        top >= 0
        && left >= 0
        && bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    if (buttonElement.getAttribute('aria-expanded') === 'false') {
      element.hidden = false;
      buttonElement.setAttribute('aria-expanded', 'true');
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          oHeaderBackdropElement.classList.remove('-hidden');
          element.classList.add('-expanded');
          if (buttonCheckoutElement && buttonCheckoutElement.hidden === false) {
            buttonCheckoutElement.focus();
          } else if (buttonContinueElement && buttonContinueElement.hidden === false) {
            buttonContinueElement.focus();
          }
          if (!isElementInViewport(buttonElement)) {
            buttonElement.scrollIntoView({
              behavior: 'smooth',
            });
          }
        });
      });
    }
  }


  hide() {
    const {
      element,
      buttonElement,
      oHeaderBackdropElement,
    } = this;

    if (buttonElement.getAttribute('aria-expanded') === 'true') {
      element.classList.remove('-expanded');
      buttonElement.setAttribute('aria-expanded', 'false');
      oHeaderBackdropElement.classList.add('-hidden');
      this.isMouseOver = false;
    }
  }


  add(item, quantity = 1, data = []) {
    const {
      element,
    } = this;

    return new Promise((resolve, reject) => {
      element.classList.add('-loading');

      (async () => {
        const response = await super.add(item, quantity, data);
        const responseData = await response.json().catch((error) => {
          console.info(error);
          reject(error.statusText);
        });

        if (response.status === 200) {
          this.data = responseData;
          this.updateCount();
          this.createCartList();
          this.show();
          resolve();
        } else {
          console.info(data);
          reject(data.message);
        }

        element.classList.remove('-loading');
      })();
    });
  }


  update(item, quantity, data = []) {
    const {
      element,
    } = this;

    return new Promise((resolve, reject) => {
      element.classList.add('-loading');

      (async () => {
        const response = await super.update(item, quantity, data);
        const responseData = await response.json().catch((error) => {
          console.info(error);
          reject(error.statusText);
        });
        element.classList.remove('-loading');

        if (response.status === 200) {
          this.data = responseData;
          this.updateCount();
          this.createCartList();
          resolve();
        } else {
          console.info(data);
          reject(data.message);
        }
      })();
    });
  }


  remove(item) {
    const {
      element,
    } = this;

    return new Promise((resolve, reject) => {
      element.classList.add('-loading');

      (async () => {
        const response = await super.remove(item);
        const data = await response.json().catch((error) => {
          console.info(error);
          reject(error.statusText);
        });
        element.classList.remove('-loading');

        if (response.status === 200) {
          this.data = data;
          this.updateCount();
          this.createCartList();
          resolve();
        } else {
          console.info(data);
          reject(data.message);
        }
      })();
    });
  }
}

export default Cart;
