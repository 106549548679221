class FormService {
  constructor(element) {
    const { cart } = window;

    element.addEventListener('submit', (event) => {
      event.preventDefault();
      const quantity = 1;
      const id = element.id.value;
      const formData = new FormData(element);
      const data = {};
      formData.forEach((value, key) => {
        if (key !== 'id') {
          data[key] = value;
        }
      });
      cart.add(id, quantity, data);
    });
  }
}

export default FormService;
