class PostStampThumb {
  constructor(element) {
    const formElement = element.querySelector('form');
    const { cart } = window;

    formElement.addEventListener('submit', (event) => {
      event.preventDefault();
      const quantity = formElement.quantity.value;
      const id = formElement.id.value;
      cart.add(id, quantity);
    });
  }
}

export default PostStampThumb;
