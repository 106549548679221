class PostBoxSearch {
  constructor(element) {
    this.inputElement = element.querySelector('input');
    this.element = element;
    this.resultsElement = document.createElement('div');
    this.resultsListElement = document.createElement('ul');
    this.attributionElement = document.createElement('div');
    this.noResultsElement = document.createElement('div');
    this.focusedResult = null;

    this.attributionElement.classList.add('m-post-box-search__attribution');
    this.noResultsElement.classList.add('m-post-box-search__no-results');
    this.resultsElement.classList.add('m-post-box-search__results');
    this.resultsElement.hidden = true;
    element.appendChild(this.resultsElement);

    this.inputElement.addEventListener('input', () => {
      const query = this.inputElement.value;
      if (query.length >= 3) {
        (async () => {
          const searchResults = await this.search(query);
          this.showResults(searchResults);
        })();
      } else {
        this.hideResults();
      }
    });

    this.inputElement.addEventListener('focus', () => {
      this.showResults();
    });

    this.inputElement.addEventListener('blur', () => {
      setTimeout(() => {
        if (!element.contains(document.activeElement)) {
          this.hideResults();
        }
      }, 60); // wait a moment to handle click events on results
    });

    element.addEventListener('submit', (event) => {
      event.preventDefault();
      if (this.focusedResultElement) {
        const url = this.focusedResultElement.querySelector('a').getAttribute('href');
        window.location.href = url;
        this.inputElement.blur();
      }
    });

    element.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.inputElement.blur();
      }
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        this.focusNextResult();
      }
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        this.focusPreviousResult();
      }
    });
  }

  async search(query) {
    return fetch(`/briefkasten-suche/${query}`, {
      cache: 'no-cache',
    }).then((response) => response.json());
  }

  showResults(searchResults) {
    const {
      resultsElement,
      resultsListElement,
      attributionElement,
      noResultsElement,
    } = this;
    const resultsUrl = '/privatkunden/verkaufsstellen-briefkaesten';
    if (searchResults) {
      resultsElement.innerHTML = '';
      resultsListElement.innerHTML = '';
      searchResults.features.forEach((feature) => {
        const liElement = document.createElement('li');
        liElement.addEventListener('mouseover', () => {
          this.focusResult(liElement);
        });
        liElement.addEventListener('click', () => {
          this.hideResults();
        });
        liElement.setAttribute('role', 'option');
        liElement.setAttribute('id', feature.id.replace('.', '-'));
        let svgIcon = '<img src="/assets/images/star--search-results.svg" width="56" height="56" loading="lazy" alt="">';
        if (feature.place_type.includes('post-box')) {
          if (feature.stampSale === true) {
            svgIcon = '<img src="/assets/images/stamp-sale--search-results.svg" width="56" height="56" loading="lazy" alt="Verkaufsstelle mit Briefkasten">';
          } else {
            svgIcon = '<img src="/assets/images/post-box--search-results.svg" width="56" height="56" loading="lazy" alt="Briefkasten">';
          }
        }
        liElement.innerHTML = `
        <a href="${resultsUrl}#${feature.id}" tabindex="-1">
          <strong>${svgIcon} ${feature.text}</strong><br>
          <em>${feature.place_name}</em>
        </a>`;
        resultsListElement.appendChild(liElement);
      });
      resultsElement.appendChild(resultsListElement);
      if (searchResults.message) {
        noResultsElement.innerText = searchResults.message;
        resultsElement.appendChild(noResultsElement);
      } else if (searchResults.attribution) {
        attributionElement.innerText = searchResults.attribution;
        resultsElement.appendChild(attributionElement);
      }
    }
    resultsElement.hidden = false;
    if (resultsListElement.childElementCount > 0) {
      this.focusedResult = 0;
      const elementToBeFocused = resultsListElement.children[0];
      this.focusResult(elementToBeFocused);
    }
  }

  hideResults() {
    const {
      resultsElement,
    } = this;
    this.focusedResult = null;
    resultsElement.hidden = true;
  }

  focusResult(elementToBeFocused) {
    const {
      resultsListElement,
      inputElement,
    } = this;

    [...resultsListElement.children].forEach((resultElement) => {
      resultElement.setAttribute('aria-selected', resultElement === elementToBeFocused);
      if (resultElement === elementToBeFocused) {
        inputElement.setAttribute('aria-activedescendant', resultElement.id);
      }
    });
    this.focusedResultElement = elementToBeFocused;
  }

  focusNextResult() {
    const {
      resultsListElement,
    } = this;

    if (this.focusedResult === null) {
      this.focusedResult = 0;
    } else if (this.focusedResult + 1 < resultsListElement.childElementCount) {
      this.focusedResult += 1;
    } else {
      this.focusedResult = 0;
    }

    const elementToBeFocused = [...resultsListElement.children][this.focusedResult];

    this.focusResult(elementToBeFocused);
  }

  focusPreviousResult() {
    const {
      resultsListElement,
    } = this;

    if (this.focusedResult === null) {
      this.focusedResult = 0;
    } else if (this.focusedResult - 1 >= 0) {
      this.focusedResult -= 1;
    } else {
      this.focusedResult = resultsListElement.childElementCount - 1;
    }

    const elementToBeFocused = [...resultsListElement.children][this.focusedResult];

    this.focusResult(elementToBeFocused);
  }
}

export default PostBoxSearch;
