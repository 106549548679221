import 'airbnb-browser-shims/browser-only';
import './polyfills/formData';
import './polyfills/element-remove';

import PostBoxSearch from './components/PostBoxSearch';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import PostStampThumb from './components/PostStampThumb';
import FormService from './components/FormService';
import Form from './components/Form';
import FileUpload from './components/FileUpload';
import Lightbox from './components/Lightbox';
import Mainnav from './components/Mainnav';
import NewsItemVideo from './components/NewsItemVideo';

import './components/cookie-consent';

const postBoxSearchElements = document.querySelectorAll('.m-post-box-search');
const cartElement = document.querySelector('.m-cart:not(.-invoice)');
const oFormCheckoutElement = document.querySelector('.o-form-checkout');
const postStampThumbElements = document.querySelectorAll('.m-post-stamp-thumb');
const formServiceElements = document.querySelectorAll('.m-form-service');
const formElements = document.querySelectorAll('.m-form[action]');
const fileUploadElements = document.querySelectorAll('.a-file-upload');
const lightboxButtonElements = document.querySelectorAll('.a-lightbox-button');
const mainnavElement = document.querySelector('.o-mainnav');
const newsItemVideoElements = document.querySelectorAll('.m-news-item__video');


function detectIE() {
  const { userAgent } = window.navigator;
  if (userAgent.includes('MSIE ') || userAgent.includes('Trident/')) {
    return true;
  }

  return false;
}


postBoxSearchElements.forEach((postBoxSearchElement) => new PostBoxSearch(postBoxSearchElement));

if (cartElement) {
  window.cart = new Cart(cartElement);
}
if (oFormCheckoutElement) {
  new Checkout(oFormCheckoutElement);
}
if (mainnavElement) {
  new Mainnav(mainnavElement);
}

window.postStampThumbs = [];
postStampThumbElements.forEach((postStampThumbElement) => {
  window.postStampThumbs.push = new PostStampThumb(postStampThumbElement);
});

window.formServices = [];
formServiceElements.forEach((formServiceElement) => {
  window.formServices.push = new FormService(formServiceElement);
});

formElements.forEach((formElement) => new Form(formElement));
fileUploadElements.forEach((fileUploadElement) => new FileUpload(fileUploadElement));
lightboxButtonElements.forEach((lightboxButtonElement) => new Lightbox(lightboxButtonElement));
newsItemVideoElements.forEach((newsItemVideoElement) => new NewsItemVideo(newsItemVideoElement));


if (detectIE()) {
  document.querySelector('body').classList.add('-ie');
}
