class FileUpload {
  constructor(element) {
    this.inputElement = element.querySelector('input[type="file"]');
    this.placeholderElement = element.querySelector('.a-file-upload__placeholder');
    this.listElement = element.querySelector('.a-file-upload__list');

    this.inputElement.addEventListener('change', () => this.updateFileList());
    this.updateFileList();
  }

  updateFileList() {
    const {
      inputElement,
      placeholderElement,
      listElement,
    } = this;

    function returnFileSize(number) {
      const numberFormat = new Intl.NumberFormat('de-DE', { style: 'decimal', maximumFractionDigits: 1 });
      if (number < 1024) {
        return `${number} Bytes`;
      } if (number >= 1024 && number < 1048576) {
        return `${numberFormat.format((number / 1024))} KB`;
      }

      return `${numberFormat.format((number / (1024 * 1024)))} MB`;
    }

    while (listElement.firstChild) {
      listElement.removeChild(listElement.firstChild);
    }

    const { files } = inputElement;
    if (files.length === 0) {
      placeholderElement.removeAttribute('hidden');
      listElement.setAttribute('hidden', '');
    } else {
      placeholderElement.setAttribute('hidden', '');
      listElement.removeAttribute('hidden');
      for (const file of files) {
        const listItemElement = document.createElement('li');
        // max file size 5 MB
        if (file.size < 1024 * 1024 * 5) {
          listItemElement.innerHTML = `${file.name} (${returnFileSize(file.size)})`;
        } else {
          listItemElement.innerHTML = `${file.name} (${returnFileSize(file.size)})<br><span class="a-error a-small">Maximale Dateigröße überschritten</span>`;
        }

        listElement.appendChild(listItemElement);
      }
    }
  }
}

export default FileUpload;
