class Mainnav {
  constructor(element) {
    const buttonElement = element.querySelector('.o-mainnav__hamburger');
    const buttonBoldElement = buttonElement.querySelector('b');
    const linkElements = element.querySelectorAll('.o-mainnav__link');
    const textOpen = buttonElement.innerText;
    const { textClose } = buttonElement.dataset;

    function updateButtonText() {
      if (element.classList.contains('-open')) {
        buttonElement.setAttribute('aria-expanded', 'true');
        buttonBoldElement.innerText = textClose;
      } else {
        buttonElement.setAttribute('aria-expanded', 'false');
        buttonBoldElement.innerText = textOpen;
      }
    }

    function onLinkElementClick(event) {
      if (element.classList.contains('-open')) {
        const linkElement = event.target;
        linkElement.classList.toggle('-open');
        event.preventDefault();
      }
    }

    buttonElement.addEventListener('click', () => {
      element.classList.toggle('-open');
      updateButtonText();
    });

    element.addEventListener('click', (event) => {
      if (event.target === element) {
        element.classList.remove('-open');
        updateButtonText();
      }
    });
    linkElements.forEach((linkElement) => {
      linkElement.addEventListener('click', onLinkElementClick);
    });
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        element.classList.remove('-open');
        updateButtonText();
      }
    });

    buttonElement.setAttribute('aria-expanded', 'false');
  }
}

export default Mainnav;
